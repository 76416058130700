"use strict";

timeago.register('de', (number, index, totalSec) => {
    return [
        ['gerade eben', 'vor einer Weile'],
        ['vor %s Sekunden', 'in %s Sekunden'],
        ['vor 1 Minute', 'in 1 Minute'],
        ['vor %s Minuten', 'in %s Minuten'],
        ['vor 1 Stunde', 'in 1 Stunde'],
        ['vor %s Stunden', 'in %s Stunden'],
        ['vor 1 Tag', 'in 1 Tag'],
        ['vor %s Tagen', 'in %s Tagen'],
        ['vor 1 Woche', 'in 1 Woche'],
        ['vor %s Wochen', 'in %s Wochen'],
        ['vor 1 Monat', 'in 1 Monat'],
        ['vor %s Monaten', 'in %s Monaten'],
        ['vor 1 Jahr', 'in 1 Jahr'],
        ['vor %s Jahren', 'in %s Jahren'],
    ][index];
});

const $ = (selector) => document.querySelector(selector);
const $all = (selector) => document.querySelectorAll(selector);
const updateObjectProperty = (obj, propPath, value) => {
    const [head, ...rest] = propPath.split('.');

    !rest.length
        ? obj[head] = value
        : updateObjectProperty(obj[head], rest.join('.'), value);
}

document.addEventListener('DOMContentLoaded', () => {
    // Replace HTML tag class name no-js with js
    document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/, 'js');

    // Cookie consent
    let $cookieConsentAccept = $('.cookie-consent-accept');
    $cookieConsentAccept.addEventListener('click', () => {
        localStorage.setItem('cookie-consent', '1');
        $('.cookie-consent-container').remove();
    });
    if (localStorage.getItem('cookie-consent')) {
        $cookieConsentAccept.dispatchEvent(new Event('click'));
    }

    // Message container
    let $messageCloseContainerSVG = $('.message-close-container svg');
    $messageCloseContainerSVG.addEventListener('click', () => {
        localStorage.setItem('message-read', '1');
        $('.info-message-container').remove();
    });
    if (localStorage.getItem('message-read')) {
        $messageCloseContainerSVG.dispatchEvent(new Event('click'));
    }

    // Sidebar
    let $sidebarButton = $('.sidebar-button');
    let $sidebarContainer = $('.sidebar-container');
    let $mapContainer = $('.map-container');
    $sidebarButton.addEventListener('click', () => {
        $sidebarButton.classList.toggle('active');
        $sidebarContainer.classList.toggle('active');
    });
    $mapContainer.addEventListener('click', (e) => {
        let traversalPath = e.path || (e.composedPath && e.composedPath());
        let containsMarker = false;
        let containsLink = false;
        traversalPath.forEach(element => {
            if (element.classList) {
                if (element.classList.contains('leaflet-marker-icon')) {
                    containsMarker = true;
                }
                if (element.classList.contains('links-container')) {
                    containsLink = true;
                }
            }
        });
        if (!containsMarker && !containsLink) {
            $sidebarButton.classList.remove('active');
            $sidebarContainer.classList.remove('active');
        } else {
            $sidebarButton.classList.add('active');
            $sidebarContainer.classList.add('active');
        }
    });

    // Map
    let countryBounds = L.latLngBounds(L.latLng(55.05, 5.87), L.latLng(45.82, 17.16));
    let map = L.map('map', {
        center: [51.1069818075, 10.385780508],
        zoom: 6,
        minZoom: 5,
        maxZoom: 18,
        zoomControl: false,
        scrollWheelZoom: false
    });
    L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}', {
        attribution: '© Kartenkacheln <a href="https://stamen.com/">Stamen Design</a>, unter <a href="https://creativecommons.org/licenses/by/3.0/deed.de">CC BY 3.0</a> | © Kartendaten <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, unter <a href="https://www.openstreetmap.org/copyright">ODbL</a>',
        subdomains: 'abcd',
        ext: 'png'
    }).on('loading', () => {
        let $loadingSpinner = $('.loading-spinner');
        if ($loadingSpinner) {
            $loadingSpinner.remove();
        }
    }).addTo(map);

    // Map controls
    L.control.zoom({
        zoomInTitle: 'Hineinzoomen',
        zoomOutTitle: 'Herauszoomen',
        position: 'bottomright'
    }).addTo(map);

    // Map icons
    let mapMarkerIconOptions = {
        iconSize: [28, 28],
        iconAnchor: [14, 14],
        tooltipAnchor: [0, 0]
    };
    let mapMarkerIcons = [
        L.divIcon(Object.assign({
            html: $('.map-marker-template .marker-restaurant').outerHTML
        }, mapMarkerIconOptions)),
        L.divIcon(Object.assign({
            html: $('.map-marker-template .marker-cafe').outerHTML
        }, mapMarkerIconOptions)),
        L.divIcon(Object.assign({
            html: $('.map-marker-template .marker-bar').outerHTML
        }, mapMarkerIconOptions)),
    ];
    let mapMarkerClicked = (e) => {
        $all('.leaflet-marker-icon').forEach(marker => {
            marker.classList.remove('active-marker');
        });
        e.target._icon.classList.add('active-marker');
    };

    // Add map marker
    window.localities.forEach(locality => {
        let icon = mapMarkerIcons[locality.type];
        updateObjectProperty(icon, 'options.html', icon.options.html.replace('UUID', locality.id));
        L.marker([locality.lat, locality.lng], { icon: icon })
            .on('click', (e) => mapMarkerClicked(e))
            .addTo(map);
    });

    // Geolocation
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            let pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            if (!countryBounds.contains(L.latLng(pos.lat, pos.lng))) {
                alert('Ihr Standort scheint nicht innerhalb von Deutschland zu sein.');
                return;
            }

            map.flyTo(pos, 14);
        }, positionError => {
            if (positionError.code !== 1) {
                alert('Ihr aktueller Standort konnte nicht ermittelt werden, bitte versuchen Sie es später noch einmal.');
            }
        }, {
            enableHighAccuracy: true
        });
    }

    // Navigation
    window.addEventListener('hashchange', () => {
        $all('.view-element.rendered-template').forEach(element => element.remove());
        let $submitButtonContainer = $('.submit-button-container');

        let page = window.location.hash.substr(1);
        let placeUUID = null;
        if (page.substr(0, 5) === 'place') {
            placeUUID = page.substr(6);
            let place = window.localities.find(locality => {
                return locality.id === placeUUID;
            });
            if (place === undefined) {
                page = '404';
            } else {
                let $contentContainer = $('.content-container');
                let templateHeader = $('h2.view-element[data-view="place"]').outerHTML;
                let templateBody = $('div.view-element[data-view="place"]').outerHTML;

                let placeTypes = ['Restaurant', 'Café', 'Bar'];
                let placeType = placeTypes[place.type];
                let svgType = placeTypes.map(str => str.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))[place.type];
                let $svg = $('.map-marker-template .marker-' + svgType);

                let svgWrapper = document.createElement('span');
                svgWrapper.classList = $svg.classList;
                svgWrapper.innerHTML = $svg.innerHTML;

                let renderedHeader = Mustache.render(templateHeader, { title: place.name });
                let renderedBody = Mustache.render(templateBody, {
                    name: place.name,
                    svg: svgWrapper.outerHTML,
                    type: placeType,
                    city: place.city,
                    postcode: place.postcode,
                    street: place.street,
                    properties: place.properties,
                    created: place.created ? timeago.format(place.created * 1000, 'de') : false,
                    updated: place.updated ? timeago.format(place.updated * 1000, 'de') : false,
                    urlencode: () => (text, render) => encodeURIComponent(render(text))
                });

                let tmpElementHeader = document.createElement('div');
                let tmpElementBody = document.createElement('div');
                tmpElementHeader.innerHTML = renderedHeader;
                tmpElementBody.innerHTML = renderedBody;
                tmpElementHeader = tmpElementHeader.firstChild;
                tmpElementBody = tmpElementBody.firstChild;

                tmpElementHeader.dataset.view = page;
                tmpElementBody.dataset.view = page;
                tmpElementHeader.classList.remove('hidden');
                tmpElementBody.classList.remove('hidden');
                tmpElementHeader.classList.add('rendered-template');
                tmpElementBody.classList.add('rendered-template');
                $contentContainer.appendChild(tmpElementHeader);
                $contentContainer.appendChild(tmpElementBody);
            }
        }

        if (page === 'vorschlag') {
            $submitButtonContainer.classList.add('hidden');
        } else {
            $submitButtonContainer.classList.remove('hidden');
        }

        let viewElements = Array.from($all('.view-element'));
        let hasVisibleViews = false;
        viewElements.forEach(element => {
            let isActive = element.dataset.view === page;
            if (isActive) {
                hasVisibleViews = true;
                element.classList.remove('hidden');
            } else {
                element.classList.add('hidden');
            }
        });
        if (!hasVisibleViews) {
            $all('.view-element[data-view="404"]').forEach(element => {
                element.classList.remove('hidden');
            });
        }
    }, false);

    // Resize handler
    window.addEventListener('resize', () => {
        // Viewport height
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Trigger resize event
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 1);

    // Trigger navigation on new page load with hash
    if (window.location.hash.length > 0) {
        setTimeout(() => {
            window.dispatchEvent(new Event('hashchange'));
        }, 500);
    }
});


let _paq = _paq || [];
_paq.push(['enableLinkTracking']);
_paq.push(['enableHeartBeatTimer']);
_paq.push(['trackVisibleContentImpressions']);
_paq.push(['setSiteId', 'XXX']);
_paq.push(['setSecureCookie', true]);
_paq.push(['trackPageView']);

(function() {
    let u = 'https://matomo.bestwebframeworks.com/';
    let d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    g.async = true;
    g.defer = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
})();
